import React from "react"
import { graphql } from "gatsby"
import {  getImage } from "gatsby-plugin-image"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import Carousel from "../components/Carousel"


const Laowa12 = ({ data, location }) => {

  const url = `https://speedcambrasil.com.br${location.pathname}`

  let carouselImages = []
  data.allCarouselInnovision.edges.forEach(element => {
    carouselImages.push({ img: getImage(element["node"]) })
  })


  return (
    <>
      <Metatags
        title="Speedcam Brasil - Laowa 12"
        description="Speed Cam - Rental House"
        url={url}
        image="https://speedcambrasil.com.br/logo.png"
        keywords="speed cam, rental, Phanton, Alexa Mini, Alexa 35, Innovision HD, Innovision HR, Cinemagic Revolution, Laowa 12, Laowa 24"
        language="pt-br"
      />
      <NavBar />
      <div className="container mx-auto text-center">
        <h1 className="text-4xl m-4 md:text-6xl font-semibold text-sc-yellow">
          <i>Laowa 12mm T2.9 Zero-D</i>
        </h1>
        <div className="h-20" />
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <Carousel picts={carouselImages} />
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl m-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-2" />
          <div className="col-span-8">
            <p>
              A Lente de Cine Zero-D Laowa 12mm T2.9 Zero-D de montagem PL da
              Venus Optics oferece um amplo ângulo de visão de 122°, adequado
              para fotos externas e de paisagem e em locais internos pequenos, e
              é otimizada para cinematografia digital. É tão leve e compacta que
              pode ser usada não apenas com disparo de mão, mas também é leve o
              suficiente para uso em cardans. A lente produz um círculo de
              imagem que cobrirá sensores de tamanho full-frame, VistaVision e
              ARRI ALEXA LF ou menores.
            </p>

            <p>
              A lente de 12 mm possui uma abertura máxima de T2.9 com 16
              elementos em 10 grupos. Sua abertura de 7 lâminas fornece um
              desfoque suave e agradável, pouca profundidade de campo com pouca
              ou nenhuma distorção e uma distância de foco mínima de 7". Os
              anéis de foco e abertura apresentam engrenagem de passo de 0,8 que
              acomodará motores follow focus standart. O diâmetro frontal de 102
              mm pode ser aumentado para 114 mm com o adaptador incluído para
              encaixar em matte boxes para evitar brilhos e reflexos, bem como
              adicionar filtros. As marcações de foco, abertura e profundidade
              de campo estão em pés na lateral da lente para auxiliar os
              foquistas.
            </p>
            <p>
              Além do anel adaptador de 114 mm, um conjunto de calços está
              incluído para calibração e um estojo Pelican rígido com espuma
              está incluído para proteger e transportar a lente.
            </p>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-3" />
          <div className="col-span-8">
            <div>
              <h1 className="my-2">Principais características:</h1>

              <ul className="list-disc list-inside">
                <li>
                  Prime T2.9 ultra grande angular de 12 mm projetado para
                  câmeras com montagem PL com sensor LF de
                  full-frame/VistaVision/ALEXA
                </li>
                <li>
                  Projetado com 16 elementos em 10 grupos, baixa distorção e
                  íris de 7 lâminas
                </li>
                <li>
                  Diâmetro frontal de 102 mm com anel adaptador de 114 mm para
                  encaixar em matte box
                </li>
                <li>
                  Foco mínimo de 7", rotação de foco suave de 270° e anel de
                  foco MOD padrão de 0,8
                </li>
                <li>Estojo Pelican rígido com espuma</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />

      <Footer />
    </>
  )
}

export default Laowa12

export const query = graphql`
  {
    allCarouselInnovision: allFile(
      filter: { relativePath: { regex: "/laowa-12/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1536)
          }
        }
      }
    }
  }
`
