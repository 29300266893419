import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination, Navigation } from "swiper"

import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "../css/swipper-hack.css"

const Carousel = props => {

  return (
    <>
      {props.picts.length > 1 ? (
        <Swiper
          slidesPerView={1}
          loop={true}
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {props.picts.map((image, k) => {
            return (
              <SwiperSlide key={k}>
                <GatsbyImage
                  image={image.img}
                  backgroundColor={false}
                  alt="Alexa Mini"
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      ) : (
        <GatsbyImage
          image={props.picts[0].img}
          backgroundColor={false}
          alt="Alexa Mini"
        />
      )}
    </>
  )
}

export default Carousel
